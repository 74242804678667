<template>
  <div class="flex flex-col items-center">
    <BaseIcon icon="exclamation-triangle" class="text-primary-400 text-5xl mt-5" />
    <h2 class="font-bold text-3xl text-primary-400 mt-5 mb-2">404! Not Found</h2>
    <p class="italic">Begärda webbadressen hittades inte</p>
  </div>
</template>

<script>
export default {
  title() {
    return `Begärda webbadressen hittades inte`;
  },
  name: "NotFound",
  data() {
    return {};
  },
};
</script>
